import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import SignUp from '../components/signup'
import { Card, CardImg, Col, Container, Row } from 'reactstrap'
import teaser from '../images/ID700-ios-large-0.png'
import Layout from '../components/layout'
import appStore from '../images/badge-download-on-the-app-store.svg'
import macAppStore from '../images/badge-download-on-the-mac-app-store.svg'

const IndexPage = () => (
  <Layout>
    <Container fluid>
      <Helmet title="home" />
      <Row noGutters>
        <Col xs={12} sm={{ size: 10, offset: 1 }}>
          <Link to="/id700/">
            <Card>
              <CardImg className="rounded-0" src={teaser} />
            </Card>
          </Link>
        </Col>
      </Row>
      <Row noGutters>
        <Col
          xs={12}
          sm={{ size: 10, offset: 1 }}
          className="pt-3 pb-3 d-flex flex-row justify-content-between"
        >
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/app/id700-for-desktop/id1597187741"
            >
              <Card className="border-0">
                <CardImg src={macAppStore} />
              </Card>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/app/id1482698043"
            >
              <Card className="border-0">
                <CardImg src={appStore} />
              </Card>
            </a>
          </div>
        </Col>
        <Col xs={12} sm={{ size: 10, offset: 1 }} className="pt-3 pb-3">
          <Link to="/id700/">
            <h3 className="font-weight-light">
              ID700 is a modern interpretation of the Buchla 700, now available
              for macOS and iOS.
            </h3>
          </Link>
        </Col>
        <Col xs={12} sm={{ size: 10, offset: 1 }} className="pr-1">
          <SignUp className="d-flex flex-column justify-content-between" />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
