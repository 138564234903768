import React from 'react'

import { Button, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap'

const SignUp = ({ className } = {}) => (
  <div {...{ className }}>
    <div>sign up for our mailing list</div>
    <Form
      action="https://modo.us18.list-manage.com/subscribe/post?u=5a3ade519b0700ef47f308d1f&amp;id=e1f9b68dd2"
      method="post"
      target="_blank"
    >
      <InputGroup>
        <Input
          type="email"
          className="form-control"
          required="required"
          name="EMAIL"
          id="mce-EMAIL"
          placeholder="your email address"
        />
        <InputGroupAddon addonType="append">
          <Button color="info">subscribe</Button>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  </div>
)

export default SignUp
